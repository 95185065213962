body {
  height: 100vh;
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "snowstorm";
  src: url("../public/snowstorm.ttf") format("truetype");
}

.p-2 {
  padding: 24px 0;
}

.img-cover {
  background-image: url("./assets/bg-img.jpg");
  background-position: bottom;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(10px); */
}
.overflow {
  backdrop-filter: blur(5px);
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.quiz {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  padding: 0 20px;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}
.container--header {
  max-width: 1240px;
}

.quiz-wrapper {
  overflow-y: auto;
  margin: 60px 0;
}

.disabled {
  pointer-events: none;
}

.check-answer-btn.disabled {
  display: none;
}
a.check-answer-btn {
  text-decoration: none !important;
}

.correct {
  background-color: rgba(0, 128, 0, 0.46) !important;
}

.wrong {
  background-color: rgba(255, 0, 0, 0.41) !important;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 60px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 253, 248);
  font-family: Arial, sans-serif;
}

.card.card--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.question-number {
  font-style: italic;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.answer-options {
  list-style: none;
}

.answer-options li {
  margin-bottom: 10px;
}

.answer-options label {
  font-size: 17px;
  display: block;
  padding: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.answer-options input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.check-answer-btn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  margin-top: 20px;
  padding: 14px 20px;
  border-radius: 3px;
  background-color: #9a321b;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
.check-answer-btn.left {
  display: block;
}
.check-answer-btn:hover {
  background-color: #7e220d;
}

/* карточка приветствия */
h1 {
  margin-top: 0;
}
.title-h1 {
  text-transform: uppercase;
  font-family: "snowstorm";
  color: #b32218;
  font-size: 46px;
  line-height: 130%;
}

.text--quiz {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 26px;
  font-weight: 900;
  line-height: 130%;
  color: #000;
}

.title-h2 {
  font-family: "snowstorm";
  color: #000;
  font-size: 24px;
  line-height: 130%;
}

.title-h2 span {
  color: #b32218;
}

.text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  color: #000;
}

.text--center {
  text-align: center;
}

.form {
  margin-top: 25px;
  width: 100%;
  max-width: 450px;
  display: inline-block;
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 16px;
  min-height: 50px;
}

button[type="submit"] {
  display: block;
  margin: 20px auto 0;
  cursor: pointer;
}

button[type="submit"].disabled {
  display: block;
  pointer-events: none;
  opacity: 0.6;
}

/* карточка поздравления */

.congratulations-card {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px;
  margin: 20px;
}

.congratulations-card h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #4c4c4c;
}

.congratulations-card p {
  font-size: 24px;
  margin-bottom: 10px;
  color: #4c4c4c;
}

.congratulations-card .score {
  font-weight: bold;
  color: #24a343;
}

.congratulations-card .fireworks {
  width: 100px;
  margin: 20px auto;
  display: block;
}

.congratulations-card .get-certificate-button {
  background-color: #24a343;
  color: #ffffff;
  border: none;
}

/* Стили для админки */

.admin-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 60px;
  border: 1px solid;
}

.admin-wrapper input {
  min-height: 40px;
}

.admin-button {
  margin-top: 10px;
  width: 100%;
  display: block;
  cursor: pointer;
  padding: 10px 10px;
}

.admin-button[data-type="error"] {
  background-color: rgba(245, 42, 42, 0.841);
}

.admin-grid {
  padding: 30px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

@media screen and (max-width: 1200px) {
  .admin-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .admin-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.admin-grid-item {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgb(238, 238, 238);
}

.admin-cell {
  padding: 10px;
  background-color: rgb(215, 215, 215);
}

.admin-cell span {
  font-weight: 600;
}

.admin-grid-item button {
  background-color: rgb(119, 101, 220);
  color: white;
}

.admin-cell.new {
  background-color: #24a34444;
}

.admin-cell.sended {
  background-color: #2481a344;
}

.admin-cell.error {
  background-color: #a3242444;
}

/* Header styles */

.header {
  z-index: 99;
  background-color: rgb(255, 253, 248);
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.header__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.header__item {
  text-transform: uppercase;
  font-family: "snowstorm";
  font-size: 20px;
  line-height: 130%;
  margin: 0 25px;
  list-style: none;
  white-space: nowrap;
}
.header__item a {
  color: #000;
  text-decoration: none;
}
.header__item a:hover {
  color: #b32218;
}
.header__logo__wrapper {
  list-style: none;
  margin: 0 120px;
}
.header__logo {
  max-height: 96px;
}

@media screen and (max-width: 1240px) {
  .header {
    position: relative;
  }
  .header__wrapper {
    padding: 12px 0;
  }
  .quiz-wrapper {
    margin: 30px 0;
  }
  .header__item {
    flex: 0 1 auto;
    margin: 15px 25px 0 0;
  }
  .header__logo__wrapper {
    text-align: center;
    order: -1;
    flex: 1 0 100%;
  }
}
@media screen and (max-width: 640px) {
  .text--quiz {
    font-size: 18px;
  }
  .card {
    padding: 30px;
  }
  .title-h1 {
    font-size: 34px;
  }
}
